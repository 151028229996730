import Vue from 'vue'
import {
  createContent,
  getContentList,
  getCultureCategoryList,
  getCityListRequest,
  getSubCategoryList,
  getContentByID,
  updateContent,
  setFeatured,
  deleteContent
} from '@/api/request/culture'
import Cookies from 'js-cookie'
import errors from '@/utils/errors'
import { globalStore } from '@/utils/global'
import moment from 'moment'

Vue.use(Cookies)

const actions = {
  CREATE_CONTENT: ({commit, dispatch}, data) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('CREATE_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {
      return createContent(token, data)
        .then(resp => {
          if (resp) {
            commit('CREATE_CONTENT_SUCCESS', resp)
            resolve(resp)
          }else {
            commit('CREATE_CONTENT_FAILED')
            reject('Error in creating content')
          }
        })
        .catch(err => {
          commit('CREATE_CONTENT_FAILED')
          reject(errors(err))
        })
    })
  },
  GET_CONTENT_LIST: ({commit}, payload) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('GET_CONTENT_LIST_REQUEST')
    return new Promise((resolve, reject) => {
      return getContentList(token, payload)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              let contentSource = resp.data.data
              let newContents = {}
              let contentsList = contentSource.map(value => {
                let category = value.category == null ? 'Category removed.': value.category
                return {
                  cover:value.cover,
                  title:value.title,
                  description:value.description,
                  content:value.content,
                  city:value.city.city,
                  city_status:value.city.show,
                  featured:value.featured == null ? false : true,
                  category: category.parent ? category.parent.title : category.title || 'Category Removed',
                  sub_category:category.parent ? category.title : 'No Sub Category' ,
                  date:moment(value.timestamp.created).format('YYYY/MM/DD h:mm A'),
                  status: value.isActive === 1 ? 'active' : 'inactive',
                  createdAt: moment(value.timestamp.created).format('YYYY/MM/DD h:mm A'),
                  createdYmd: moment(new Date(value.timestamp.created)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.timestamp.created)).format('HH:mm:SS'),
                  actions: value._id
                }
              })

              // newContents['all'] = contentsList.filter(admin => admin.isDeleted === 0)
              newContents['all'] = contentsList.filter(content => content)
              newContents['featured'] = contentsList.filter(content => content.featured === true)
              commit('SET_CONTENT_LIST_SUCCESS', newContents)
              resolve(newContents)
            } else {
              commit('GET_CONTENT_LIST_FAILED')
            }
          }
        })
        .catch(err => {
          commit('GET_CONTENT_LIST_FAILED')
          reject(errors(err))
        })
    })
  },
  GET_CONTENT_BY_ID: ({commit, dispatch}, contentID) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('GET_CONTENT_BY_ID_REQUEST')
    return new Promise((resolve, reject) => {
      return getContentByID(token, contentID)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('SET_CONTENT_BY_ID', resp.data)
              resolve(resp.data)
            } else {
              commit('GET_CONTENT_BY_ID_FAILED')
            }
          }
        })
        .catch(err => {
          commit('GET_CONTENT_BY_ID_FAILED')
          reject(errors(err))
        })
    })
  },
  UPDATE_CONTENT: ({commit, dispatch}, {contentID, contentData}) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {
      return updateContent(token, contentID, contentData)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONTENT_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONTENT_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONTENT_FAILED')
          reject(errors(err))
        })
    })

  },
  SET_FEATURED: ({commit, dispatch}, {contentID, contentData}) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {
      return setFeatured(token, contentID, contentData)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONTENT_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONTENT_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONTENT_FAILED')
          reject(errors(err))
        })
    })

  },
  DELETE_CONTENT: ({commit, dispatch}, contentID) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {
      return deleteContent(token, contentID)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONTENT_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONTENT_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONTENT_FAILED')
          reject(errors(err))
        })
    })

  },
  GET_CULTURE_CATEGORY_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getCultureCategoryList(token, payload)
        .then(async result => {
          if (result) {
            commit('setCultureCategoryList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_SUB_CATEGORY_LIST ({commit}, parentCategoryID) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getSubCategoryList(token, parentCategoryID)
        .then(async result => {
          if (result) {
            commit('setSubCategoryList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_CITY_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getCityListRequest(token, payload)
        .then(async result => {
          if (result) {
            commit('setCityList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  setCityList (state, payload) {
    state.loading = false
    state.loadedOnce = true
    state.cities = payload.data
  },
  setSubCategoryList (state, payload) {
    state.loading = false
    state.loadedOnce = true
    state.subCategories = payload.data
  },
}

export default actions
