require('dotenv').config()
var config = require('12factor-config')

var cfg = config({
    apiHost : {
        env      : 'VUE_APP_API_HOST',
        type     : 'string',
        default  : 'rising-wild-api-dev.herokuapp.com/', //vobo-api-v2.herokuapp.com
        required : true,
    },
    apiPort : {
        env      : 'VUE_APP_API_PORT',
        type     : 'string',
        default  : '', //no value
        required : true,
    },
    apiProtocol : {
        env      : 'VUE_APP_API_PROTOCOL',
        type     : 'string',
        default  : 'https', //http
        required : true,
    },
    host : {
        env      : 'VUE_APP_HOST',
        type     : 'string',
        default  : 'localhost',
        required : true,
    },
    protocol : {
        env      : 'VUE_APP_PROTOCOL',
        type     : 'string',
        default  : 'http',
        required : true,
    },
    port : {
        env      : 'VUE_APP_PORT',
        type     : 'string',
        default  : '3001',
        required : true,
    },
    debugMode : {
        env      : 'VUE_APP_DEBUG',
        type     : 'boolean',
        default  : true,
        required : true,
    },
    secret : {
        env      : 'VUE_APP_SECRET',
        type     : 'string',
        default  : '296i5p254d370s8g4h456gs962dd3379e1d',
        required : true,
    },
    maxSuggetions : {
        env      : 'VUE_APP_MAX_SUGGESTIONS',
        type     : 'integer',
        default  : 10,
        required : true,
    },
    filesDir : {
        env      : 'VUE_APP_IMAGES_DIR',
        type     : 'string',
        default  : './public/files/',
        required : true
    },
    errorUnAuthorized : {
        env      : 'VUE_APP_ERROR_UNAUTHORIZED',
        type     : 'string',
        default  : 'error_unauthorized',
        required : true
    },
    companyName : {
        env      : 'VUE_APP_COMPANY_NAME',
        type     : 'string',
        default  : 'Vobo',
        required : true,
    },
    google_storage_url: {
      env      : 'VUE_APP_GOOGLE_STORAGE_URL',
      type     : 'string',
      default  : ''
    },
    appStripeCheckoutUrl: {
      env      : 'VUE_APP_STRIPE_CHECKOUT_URL',
      type     : 'string',
      default  : ''
    },
    appStripeSKLiveKey: {
      env      : 'VUE_APP_STRIPE_SK_LIVE_KEY',
      type     : 'string',
      default  : ''
    },
    appStripePKLiveKey: {
      env      : 'VUE_APP_STRIPE_PK_LIVE_KEY',
      type     : 'string',
      default  : ''
    },
    appStripeSKTestKey: {
      env      : 'VUE_APP_STRIPE_SK_TEST_KEY',
      type     : 'string',
      default  : ''
    },
    appStripePKTestKey: {
      env      : 'VUE_APP_STRIPE_PK_TEST_KEY',
      type     : 'string',
      default  : ''
    },
    env : {
      env      : 'NODE_ENV',
      type     : 'enum',
      default  : 'development',
      values   : ['development', 'production', 'staging', 'test'],
    }
})

module.exports = cfg
