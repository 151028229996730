import $http from '../utils/http'

/*
  $http (url, data, method, header)
*/

const createCheckoutSection = (token) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/create-checkout-session`, {}, 'POST', header)
}

const registerAdvertisement = (token, data) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements`, data, 'POST', header)
}

const validateAdvertisement = (token, data) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/validate`, data, 'POST', header)
}

const getAdvertisementList = (token) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements`, null, 'GET', header)
}

const createAdvertisement = (token, advertisementData) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/create`, advertisementData, 'POST', header)
}

const getAdvertisementByID = (token, advertisementID) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/${advertisementID}`, null, 'GET', header)
}

const updateAdvertisement = (token, advertisementData, id) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/${id}`, advertisementData, 'PUT', header)
}

const getAdvertisementCategoryList = (token, query) => {
  const header = (token) ? { 'authorization': token } : null
  console.log('QUERY IN', query);
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : ''
  console.log('QUERY OUT', query);
  return $http(`/api/categories${query}`, null, 'GET', header)
}

const paymentSuccess = (token, advertisementID) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/payment-success/${advertisementID}`, {}, 'PUT', header)
}
const approveAdvertisement = (token, advertisementID) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/approve/${advertisementID}`, {}, 'PUT', header)
}
const rejectAdvertisement = (token, advertisementID, advertisementData) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/reject/${advertisementID}`, advertisementData, 'PUT', header)
}

const setAsFeatured = (token, advertisementID, advertisementData) => {
  const header = (token) ? { 'authorization': token } : null
  return $http(`/api/advertisements/${advertisementID}/set-featured`, advertisementData, 'PUT', header)
}



export {
  getAdvertisementList,
  getAdvertisementByID,
  setAsFeatured,
  approveAdvertisement,
  rejectAdvertisement,
  paymentSuccess,
  getAdvertisementCategoryList,
  updateAdvertisement,
  registerAdvertisement,
  validateAdvertisement,
  createAdvertisement,
  createCheckoutSection
}
