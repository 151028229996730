var filters = {}

filters.capitalizeWords = str => {
  return str.replace(/(?:^|\s)\S/g, function (a) {
    return a.toUpperCase()
  })
}

filters.validateEmail = val => {
  var re = val === '' || /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(val)
}

filters.currencyFormater = (value, sign) => {
  const val = value !== undefined ? value : 0

  const item = val.toLocaleString(undefined, {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  })

  return `${sign} ${item}`
}

filters.ordinalNumber = i => {
  let j = i % 10
  let k = i % 100

  if (j === 1 && k !== 11) {
    return i + 'st'
  }

  if (j === 2 && k !== 12) {
    return i + 'nd'
  }

  if (j === 3 && k !== 13) {
    return i + 'rd'
  }

  return i + 'th'
}

filters.ellipsis = (paragraph, scope, scopeType='paragraph') => {
  if (scopeType === 'paragraph') {
    var resultArr = paragraph.split(' ')
    if (resultArr.length > scope) {
      resultArr = resultArr.slice(0, scope)
      var result = resultArr.join(' ') + '...'
    } else {
      return paragraph
    }
  } else if (scopeType === 'characters') {
    if (paragraph.length > scope) {
      var result = paragraph.substring(0, scope) + '...'
    } else {
      return paragraph
    }
  }

  return result
}

filters.stripHtml = str => {
  return str.replace(/(<([^>]+)>)/ig,"")
}

filters.reduceWords = words => {
  var resultArr = words.split(' ')
  if (resultArr.length > 2) {
    resultArr = resultArr.slice(0, 2)
    var result = resultArr.join(' ')
  }
  return result || words
}

filters.filterGroup = (arr, field, search) => {
  return arr.filter(x => x[field].some(y => search.some(z => z === y))).
  map(x => {
   x[field] = x[field].filter(y => search.some(z => z === y));
   return x;
  })
}

filters.getBrowserInfo = (isBrowser) => {
    var ua= navigator.userAgent, tem,
    M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || []
    if(/trident/i.test(M[1])){
        tem=  /\brv[ :]+(\d+)/g.exec(ua) || []
        return 'IE '+(tem[1] || '')
    }
    if(M[1]=== 'Chrome'){
        tem= ua.match(/\b(OPR|Edge)\/(\d+)/)
        if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera')
    }
    M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?']
    if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1])
    return M
}

filters.isNumber = n => {
  return /^-?[\d.]+(?:e-?\d+)?$/.test(n)
}

filters.stringToSlug = str => {
  str = str.replace(/^\s+|\s+$/g, '') // trim
  str = str.toLowerCase()

  // remove accents, swap ñ for n, etc
  var from = "àáäâèéëêìíïîòóöôùúüûñç·/_,:;"
  var to   = "aaaaeeeeiiiioooouuuunc------"
  for (var i=0, l=from.length ; i<l ; i++) {
    str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
  }

  str = str.replace(/[^a-z0-9 -]/g, '') // remove invalid chars
    .replace(/\s+/g, '-') // collapse whitespace and replace by -
    .replace(/-+/g, '-'); // collapse dashes

  return str
}

const utils = {
  install (Vue, options) {
    for (var key in filters) {
      if (filters.hasOwnProperty(key)) {
        if (Vue.filter(key)) {
          /*console.log(Vue.filter(key))
          console.warn(
            '[filter duplication]: A filter named ' +
              key +
              ' has already been installed.'
          )*/
        } else {
          Vue.filter(key, filters[key])
        }
      }
    }
  }
}

export default utils

if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(utils)
}
