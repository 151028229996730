export default {
  items: [
    {
      separator: true,
      id: 'separator1',
      index: 0,
      userGroup: [1]
    },
    {
      title: true,
      name: 'Home',
      id: 'home',
      index: 1,
      userGroup: [1]
    },
    {
      name: 'Dashboard',
      url: '/dashboard',
      icon: 'fas fa-tachometer-alt',
      access: ['dashboard'],
      id: 'dashboard',
      index: 2,
      userGroup: [1]
    },
    {
      name: 'Mobile Home',
      url: '/contents/home',
      icon: 'fa fa-home',
      access: ['admin'],
      id: 'videos',
      index: 3,
      userGroup: [1]
    },
    {
      name: 'Send Message',
      url: '/notifications',
      icon: 'fa fa-bell	',
      access: ['admin'],
      id: 'notificationsList',
      index: 4,
      userGroup: [1]
    },
    // {
    //   name: 'Black Card',
    //   url: '/black-card',
    //   icon: 'fa fa-credit-card-alt',
    //   access: ['admin'],
    //   id: 'blackCard',
    //   index: 5,
    //   userGroup:[1]
    // },
    {
      separator: true,
      id: 'separator1',
      index: 6,
      userGroup: [1]
    },
    {
      title: true,
      name: 'Settings',
      id: 'setting',
      index: 7,
      userGroup: [1]
    },
    {
      name: 'Contact Info',
      url: '/settings/app-configs',
      icon: 'fas fa-cogs',
      access: ['admin'],
      id: 'app-config',
      index: 8,
      userGroup: [1]
    },
    {
      name: 'Categories',
      url: '/contents/categories',
      icon: 'fa fa-tags',
      access: ['admin'],
      id: 'categories',
      index: 9,
      userGroup: [1]
    },
    {
      name: 'Cities',
      url: '/contents/cities',
      icon: '	fas fa-map-marked-alt',
      access: ['admin'],
      id: 'cities',
      index: 10,
      userGroup: [1]
    },
    {
      name: 'Subscriptions',
      url: '/settings/subscriptions',
      icon: 'fa fa-credit-card-alt',
      access: ['admin'],
      id: 'cities',
      index: 11,
      userGroup: [1]
    },
    {
      name: 'Import Business',
      url: '/settings/import-business',
      icon: 'fas fa-address-card',
      access: ['admin'],
      id: 'cities',
      index: 11,
      userGroup: [1]
    },
    {
      separator: true,
      id: 'separator2',
      index: 12,
      userGroup: [1, 2]
    },
    {
      title: true,
      name: 'Users',
      id: 'users',
      index: 13,
      userGroup: [1, 2]
    },
    {
      name: 'Business',
      url: '/client/business',
      icon: 'fas fa-store-alt',
      access: ['users'],
      id: 'mobileUsers',
      index: 14,
      userGroup: [2]
    },
    {
      name: 'Subscription',
      url: '/client/subscription',
      icon: 'fa fa-credit-card-alt',
      access: ['users'],
      id: 'mobileUsers',
      index: 15,
      userGroup: [2]
    },
    {
      name: 'User Account',
      url: '/client/profile',
      icon: 'fa fa-users',
      access: ['users'],
      id: 'mobileUsers',
      index: 14,
      userGroup: [2]
    },
    // {
    //   name: 'Black Card Url',
    //   url: '/client/black-card',
    //   icon: 'fa fa-users',
    //   access: ['users'],
    //   id: 'mobileUsers',
    //   index: 14,
    //   userGroup: [2]
    // },
    {
      name: 'Admin Users',
      url: '/user/admins',
      icon: 'fas fa-user-lock',
      access: ['admin'],
      id: 'adminUsers',
      index: 16,
      userGroup: [1]
    },
    {
      name: 'Client Web Users',
      url: '/user/business-clients',
      icon: 'fas fa-address-card',
      access: ['admin'],
      id: 'businessClientUsers',
      index: 17,
      userGroup: [1]
    },
    {
      separator: true,
      id: 'separator2',
      index: 18,
      userGroup: [1]
    },
    {
      title: true,
      name: 'Business',
      id: 'Business',
      index: 19,
      userGroup: [1]
    },
    {
      name: 'Business List',
      url: '/business/list',
      icon: 'fas fa-store-alt',
      access: ['admin'],
      id: 'businessList',
      index: 20,
      userGroup: [1]
    },
    {
      separator: true,
      id: 'separator2',
      index: 21,
      userGroup: [1]
    },
    {
      title: true,
      name: 'Advertisement',
      id: 'Advertisement',
      index: 22,
      userGroup: [1]
    },
    {
      name: 'Advertisement List',
      url: '/advertisement/list',
      icon: 'fas fa-shopping-bag',
      access: ['admin'],
      id: 'advertisementList',
      index: 23,
      userGroup: [1]
    },
    {
      separator: true,
      id: 'separator4',
      index: 24,
      userGroup: [1, 2]
    },
    {
      title: true,
      name: 'Contents',
      id: 'contents',
      index: 25,
      userGroup: [1]
    },
    {
      name: 'Community (Updates)',
      url: '/business/ads/list',
      icon: 'fa fa-group',
      access: ['admin'],
      id: 'businessAdsList',
      index: 26,
      userGroup: [1]
    },
    {
      name: 'Connections (Events)',
      url: '/connections/list',
      icon: 'fa fa-calendar',
      access: ['admin'],
      id: 'connectionList',
      index: 27,
      userGroup: [1]
    },
    {
      name: 'Culture (Blogs)',
      url: '/culture/content/list',
      icon: 'fa fa-file-text-o',
      access: ['admin'],
      id: 'communityContent',
      index: 28,
      userGroup: [1]
    },
    {
      spacer: true,
      id: 'spacer',
      index: 29,
      userGroup: [1, 2]
    }
  ]
}
