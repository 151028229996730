import $http from '../utils/http'

export function getContent (token, query) {
  const header = (token) ? { authorization: token } : null
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : ''
  return $http(`/api/home/featured${query}`, null, 'GET', header)
}

export function createContent (token, data) {
  const header = { 'Content-Type': 'multipart/form-data' }

  if (token) {
    header.authorization = token
  }

  return $http(`/api/home/featured`, data, 'POST', header)
}

export function updateContent (token, data) {
  const header = { 'Content-Type': 'multipart/form-data' }
  const id = JSON.parse(data.get('content'))._id

  if (token) {
    header.authorization = token
  }

  return $http(`/api/home/featured/${id}`, data, 'PUT', header)
}

export function deleteContent (token, id) {
  const header = token ? { authorization: token } : null
  return $http(`/api/home/featured/${id}`, null, 'DELETE', header)
}
