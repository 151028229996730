const state = {
  loading: false,
  success: false,
  error: false,
  advertisementList: {},
  selectedAdvertisement: {},
  AdvertisementInfo: {},
  advertisementCategories: [],
}

export default state
