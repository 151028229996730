const state = {
  loading: false,
  updateLoading: false,
  loadedOnce: false,
  usersLoadedOnce: false,
  groupLoading: false,
  success: false,
  error: false,
  groupSuccess: false,
  groupError: false,
  errorUser: false,
  errorGroup: false,
  userList: [],
  groupList: [],
  selectedUser: {},
  clientLoading: false,
  clientSuccess: false,
  clientError: false,
  clientLoadedOnce: false,
  selectedClient: {},
  clientList: [],
  userSubscription: {}
}

export default state
