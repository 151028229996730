import Vue from 'vue'
import {
  getClientRoleListRequest,
  getRoleListRequest,
  getUserListRequest,
  addRole,
  updateRole,
  deleteRole,
  getRoleById,
  setUserRole,
  getUserRoleById,
  updateUserRoleById
} from '@/api/request/roles'
import Cookies from 'js-cookie'
import errors from '@/utils/errors'
import { globalStore } from '@/utils/global'

Vue.use(Cookies)

const actions = {
  GET_ROLE_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getRoleListRequest(token, payload)
        .then(async result => {
          if (result) {
            commit('setRoleList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_CLIENT_ROLE_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getClientRoleListRequest(token, payload)
        .then(async result => {
          if (result) {
            commit('setClientRoleList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  getUserList ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getUserListRequest(token, payload)
        .then(async result => {
          if (result) {
            commit('setUserList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('setErrorUser', err)
          reject(errors(err))
        })
    })
  },
  GET_ROLE_BY_ID ({commit}, roleID) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getRoleById(token, roleID)
        .then(async result => {
          if (result) {
            commit('SET_ROLE_BY_ID', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          console.error(err)
          commit('ERROR_GET_ROLE', err)
          reject(errors(err))
        })
    })
  },
  addRole ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return addRole(token, payload)
        .then(async result => {
          if (result) {
            commit('success')
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  updateRole ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return updateRole(token, payload)
        .then(async result => {
          if (result) {
            commit('success')
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  deleteRole ({commit}, id) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return deleteRole(token, id)
        .then(async result => {
          if (result) {
            commit('success')
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  setUserRole ({commit}, id) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return setUserRole(token, id)
        .then(async result => {
          if (result) {
            commit('success')
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_USER_ROLE_BY_ID ({commit, dispatch, state}, userID) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getUserRoleById(token, userID)
        .then(async result => {
          if (result.data) {
            const userData = {
              _id: result.data._id,
              username: result.data.username,
              roleID: result.data.role.roleID,
              firstname: result.data.personalInfo.firstname,
              lastname: result.data.personalInfo.lastname,
              email: result.data.email
            }
            commit('setUserRoleById', userData)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  updateUserRoleById ({commit, dispatch, state}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      const {userID, roleID} = payload
      const role = {
        role: {
          roleID: roleID
        }
      }
      return updateUserRoleById(token, userID, role)
        .then(async result => {
          if (result.data) {
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  }
}

export default actions
