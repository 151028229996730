import { ACCESS_TOKEN } from './cookies'

export const headersWithoutToken = {
  'Content-Type': 'application/x-www-form-urlencoded'
}

export const headers = {
  Authorization: ACCESS_TOKEN,
  'Content-Type': 'application/x-www-form-urlencoded'
}

export const headersJson = {
  Authorization: ACCESS_TOKEN,
  'Content-Type': 'application/json'
}

export const headersJsonOnly = {
  'Content-Type': 'application/json'
}

export const headersFormData = {
  Authorization: ACCESS_TOKEN
}
