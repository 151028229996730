const mutations = {

  REGISTER_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  REGISTER_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  REGISTER_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
  VALIDATE_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  VALIDATE_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  VALIDATE_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
  GET_ADVERTISEMENT_LIST_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  SET_ADVERTISEMENT_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.advertisementList = data
  },
  SET_ADVERTISEMENT_INFO: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.AdvertisementInfo = data
  },
  GET_ADVERTISEMENT_LIST_FAILED: (state) => {
    state.loading = false
    state.advertisementList = {}
  },
  GET_ADVERTISEMENT_BY_ID_REQUEST: (state) => {
    state.loading = true
  },
  SET_ADVERTISEMENT_BY_ID: (state, data) => {
    state.loading = false
    state.selectedAdvertisement = data
  },
  GET_ADVERTISEMENT_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedAdvertisement = {}
  },
  CREATE_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  CREATE_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  CREATE_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
  UPDATE_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
  GET_ADVERTISEMENT_CATEGORY_LIST_REQUEST: (state) => {
    // causes error Uncaught (in promise) Error: [vee-validate] Validating a non-existent field: "#6". Use "attach()" first.
    // https://stackoverflow.com/questions/65899385/vee-validate-validating-a-non-existent-field-error-in-console
    // state.loading = true
  },
  SET_ADVERTISEMENT_CATEGORY_LIST: (state, payload) => {
    state.loading = false
    state.advertisementCategories = payload.data
  },
  GET_ADVERTISEMENT_CATEGORY_LIST_FAILED: (state) => {
    state.loading = false
    state.error = true
    state.success = false
  },
  APPROVE_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  APPROVE_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  APPROVE_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
  REJECT_ADVERTISEMENT_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  REJECT_ADVERTISEMENT_SUCCESS: (state) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  REJECT_ADVERTISEMENT_FAILED: (state) => {
    state.loading = false
    state.success = false
    state.error = true
  },
}
export default mutations
