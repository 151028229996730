const mutations = {
  AUTH_REQUEST: state => {
    state.status = 'loading'
  },
  AUTH_SUCCESS: (state, resp) => {
    state.status = 'success'
    state.token = resp.data.token
    state.isFirstTimeLogin = resp.data.isFirstTimeLogin
    state.hasLoadedOnce = true,
    state.userGroup = resp.userGroup
  },
  AUTH_ERROR: state => {
    state.userEmail = ''
    state.deactivated = false
    state.status = 'error'
    state.hasLoadedOnce = true
  },
  AUTH_USER_NOT_VERIFIED: (state, user) => {
    state.userEmail = user.email
  },
  AUTH_USER_DEACTIVATED: (state, user) => {
    state.userEmail = user.email
    state.deactivated = true
  },
  AUTH_TOKEN_EXPIRED: (state) => {
    state.tokenExpired = true
    state.isAuthenticated = false
  },
  AUTH_LOGOUT: state => {
    state.token = ''
    state.status = ''
    state.hasLoadedOnce = false
    state.isFirstTimeLogin = false
    state.userEmail = ''
    state.forgotPasswordToken = ''
    state.forgotPasswordStatus = ''
    state.passwordChangesStatus = ''
    state.passwordChangesStatusToken = ''
    state.confirmPasswordTokenStatus = ''
    state.userGroup = ''
  },
  FORGOT_PASSWORD_REQUEST: state => {
    state.status = 'loading'
  },
  FORGOT_PASSWORD_SUCCESS: (state, resp) => {
    state.forgotPasswordStatus = 'success'
    state.forgotPasswordToken = resp.data
    state.hasLoadedOnce = true
  },
  FORGOT_PASSWORD_ERROR: state => {
    state.forgotPasswordStatus = 'error'
    state.hasLoadedOnce = true
  },
  CONFIRM_PASSWORD_TOKEN_REQUEST: state => {
    state.confirmPasswordTokenStatus = 'loading'
  },
  CONFIRM_PASSWORD_TOKEN_SUCCESS: (state, resp) => {
    state.confirmPasswordTokenStatus = 'success'
  },
  CONFIRM_PASSWORD_TOKEN_ERROR: state => {
    state.confirmPasswordTokenStatus = 'error'
  },
  RESET_DATA: (state) =>{
    state.token = ''
    state.tokenExpired = false
    state.isFirstTimeLogin = 0
    state.status = ''
    state.hasLoadedOnce = false
    state.userEmail = ''
    state.forgotPasswordToken = ''
    state.forgotPasswordStatus = ''
    state.passwordChangesStatus = ''
    state.passwordChangesStatusToken = ''
    state.confirmPasswordTokenStatus = ''
  }
}

export default mutations
