import Vue from 'vue'
import Cookies from 'js-cookie'
import {
  getCategories,
  createCategory,
  updateCategory,
  updateCategoryOrder,
  deleteCategory
} from '@/api/request/categories'

Vue.use(Cookies)

const actions = {
  GET_CATEGORIES: async ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { success, data, message } } = await getCategories(token, query)
      commit('loading', false)
      commit('categories', data)
      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  UPDATE_CATEGORY: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { success, data, message } } = await updateCategory(token, formData)
      commit('loading', false)
      dispatch('GET_CATEGORIES')
      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  UPDATE_CATEGORY_ORDER: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { success, data, message } } = await updateCategoryOrder(token, formData)
      commit('loading', false)
      dispatch('GET_CATEGORIES')
      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  CREATE_CATEGORY: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { data } } = await createCategory(token, formData)
      dispatch('GET_CATEGORIES')
      return data
    } catch (error) {
      const { success, message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  DELETE_CATEGORY: async ({ commit, dispatch }, id) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { success, data, message } } = await deleteCategory(token, id)
      commit('loading', false)

      dispatch('GET_CATEGORIES')

      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
}

export default actions
