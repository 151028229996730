import Vue from 'vue'
import * as Cookies from 'js-cookie'

Vue.use(Cookies)

const state = {
  status: '',
  profileLoaded: false,
  firstname: '',
  lastname: '',
  username: '',
  email: '',
  phone: '',
  userID: '',
  avatar: '',
  loading: false,
  updateProfileLoading: false,
  billingUpdateRequired: false,
  registerLoading: false,
  resetPasswordStatus: false,
  resetPasswordToken: false,
  hasLoadedOnce: false,
  dashboardErrors: false,
  dashboardSuccess: false,
  dashboardStatus: false,
  dashboard: {
    androidSubscribers: false,
    iosSubscribers: false,
    clients: false,
    dashboardUsersYear: []
  },
  userEmail: '',
  forgotPasswordToken: '',
  forgotPasswordStatus: '',
  confirmPasswordTokenStatus: '',
  userGroup: ''
}

export default state
