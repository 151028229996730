import Vue from 'vue'
import $http from '../utils/http'
import * as Cookies from 'js-cookie'

Vue.use(Cookies)

/*
  $http (url, data, method, header)
*/

const getUserReport = (token) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/user/reports`, null, 'GET', header)
}

const getUserReportFilter = (token, startDate, endDate) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/user/reports/${startDate}/${endDate}`, null, 'GET', header)
}

export {
  getUserReport,
  getUserReportFilter
}
