<template>
  <div id="mainWrapper" class="wrapper">
    <notifications></notifications>
    <dashboard-navbar :type="$route.meta.navbarType"></dashboard-navbar>
    <side-bar>
      <template slot="links" v-for="(item, index) in navItems">
        <hr class="my-2" v-if="item.separator" :key="item.id + index" />
        <div class="my-8" v-if="item.spacer" :key="item.id + index"></div>
        <h6
          class="navbar-heading p-0 text-gray"
          v-else-if="item.title"
          :key="item.id + index"
        >
          {{ item.name }}
        </h6>
        <div v-else-if="item.url" :key="item.id + index">
          <sidebar-item
            :link="{
              name: item.name,
              icon: item.icon,
              path: item.url,
            }"
          >
          </sidebar-item>
        </div>
        <div v-else :key="index + item.id">
          <sidebar-item
            :link="{
              name: item.name,
              icon: item.icon + ' text-primary',
            }"
          >
            <div v-if="item.children">
              <sidebar-item
                v-for="(subItem, subindex) in item.children"
                :key="subItem.name + subindex"
                :link="{
                  name: subItem.name,
                  path: subItem.url,
                  icon: subItem.icon + ' text-primary',
                }"
              />
            </div>
          </sidebar-item>
        </div>
      </template>
    </side-bar>
    <div class="main-content">
      <div @click="$sidebar.displaySidebar(false)">
        <fade-transition :duration="200" origin="center top" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
        </fade-transition>
      </div>
      <content-footer v-if="!$route.meta.hideFooter"></content-footer>
    </div>
    <b-modal
      v-model="openBillingModal"
      size="m"
      hide-header-close
      no-close-on-esc
      no-close-on-backdrop
      centered
      modal-class="custom-modal"
    >
      <template v-slot:modal-title>
        <span style="color: #000; text-align: center"
          >Add Card Information</span
        >
      </template>
      <b-row v-if="billing.temp.subscriptionLoading">
        <div class="col-lg-12 text-center">
          <pulse-loader color="var(--pulse-loader)"></pulse-loader>
        </div>
      </b-row>
      <div v-else class="modalData">
        <!-- <div class="modal-description" style="color: var(--gray)">
          Your card information is required to continue
        </div> -->
        <b-form-group role="group" class="has-feedback">
          <label class="w-100 form-control-label" for="website"
            >Cardholder Name</label
          >
          <b-form-input
            name="cardHolder"
            v-model.trim="billing.card.fullName"
            data-vv-as="cardHolder"
            data-vv-name="cardHolder"
            v-validate="'required'"
            autocomplete="NoAutocomplete"
            placeholder="Full Name"
            size="m"
            class="form-control form-card no-instruction"
            :class="{
              input: true,
              'is-invalid': errors.has('cardHolder'),
              'has-invalid': errors.has('cardHolder'),
            }"
          >
          </b-form-input>
          <span class="has-icon-feedback"></span>
          <div class="invalid-feedback" :is-invalid="errors.has('cardHolder')">
            {{ errors.first("cardHolder") }}
          </div>
        </b-form-group>

        <div class="form-group">
          <label for="card-element" class="form-control-label"
            >Card Information</label
          >
          <div class="has-label">
            <div id="card-element" class="form-control form-card"></div>
            <div v-if="billing.temp.hasCardError" class="card-error-msg">
              {{ billing.temp.cardError }}
            </div>
          </div>
          <div
            class="invalid-feedback"
            style="display: block"
            v-if="billing.temp.cardInput && billing.temp.cardInput.error"
          >
            {{ billing.temp.cardInput.error.message }}
          </div>
        </div>

        <div class="row">
          <div class="col-lg-12">
            <b-form-group role="group" class="has-feedback">
              <label class="w-100 form-control-label" for="address"
                >Address</label
              >
              <b-form-input
                name="address"
                v-model.trim="billing.address.line1"
                data-vv-as="address address"
                v-validate="'required'"
                size="sm"
                data-vv-name="address"
                placeholder="Address"
                autocomplete="billing street-address"
                class="form-control form-card no-instruction"
                :class="{
                  input: true,
                  'is-invalid': errors.has('address'),
                  'has-invalid': errors.has('line1'),
                }"
              >
              </b-form-input>
              <span class="has-icon-feedback"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('address')">
                {{ errors.first("address") }}
              </div>
            </b-form-group>
          </div>
        </div>

        <div class="row">
          <div class="col-lg-5">
            <b-form-group role="group" class="mb-3 has-feedback">
              <label class="w-100 form-control-label" for="billingCity"
                >City</label
              >
              <b-form-input
                type="text"
                name="billingCity"
                v-model.trim="billing.address.city"
                size="sm"
                data-vv-as="City"
                v-validate="'required'"
                data-vv-name="billingCity"
                autocomplete="billing address-level2"
                placeholder="City"
                class="form-control form-card no-instruction"
                :class="{
                  input: true,
                  'is-invalid': errors.has('billingCity'),
                  'has-invalid': errors.has('billingCity'),
                }"
              >
              </b-form-input>
              <span class="has-icon-feedback"></span>
              <div
                class="invalid-feedback"
                :is-invalid="errors.has('billingCity')"
              >
                {{ errors.first("billingCity") }}
              </div>
            </b-form-group>
          </div>
          <div class="col-lg-7">
            <base-input label="State" rules="required">
              <b-form-select
                name="state"
                v-model="billing.address.state"
                :options="billing.temp.stateOptions"
                data-vv-as="state"
                size="sm"
                v-validate="'required'"
                data-vv-name="state"
                placeholder="Enter Your state"
                autocomplete="billing address-level1"
                class="form-select form-card"
                :class="{
                  select: true,
                  'is-invalid': errors.has('state'),
                  'has-invalid': errors.has('state'),
                }"
              >
              </b-form-select>
              <span class="has-icon-feedback state-error-icon"></span>
              <div class="invalid-feedback" :is-invalid="errors.has('state')">
                {{ errors.first("state") }}
              </div>
            </base-input>
          </div>
        </div>
      </div>
      <template v-slot:modal-footer>
        <b-button type="flat" variant="medium" size="m" @click="closeModal()"
          >Later</b-button
        >
        <div style="flex: 1"></div>
        <b-button
          id="cardFormAction"
          type="flat"
          variant="primary"
          size="m"
          @click="addCard()"
        >
          <b-icon
            icon="arrow-clockwise"
            animation="spin"
            class="btn-loader hidden"
            font-scale="1"
          ></b-icon>
          Add
        </b-button>
      </template>
    </b-modal>
    <b-modal
      id="browser_warning"
      ref="browser_warning"
      title="Warning"
      no-close-on-esc
      no-close-on-backdrop
      hide-header
      :header-text-variant="'light'"
      hide-footer
      :header-bg-variant="'warning'"
    >
      <div class="p-2 pl-3 w-100 title-temp">
        <h4 class="w-75">Warning</h4>
        <a @click="closeWarning" class="closebtn">×</a>
      </div>
      <div class="body-temp d-block text-center w-100">
        For best results please use latest version of Chrome browser
      </div>
    </b-modal>
  </div>
</template>
<script>
/* eslint-disable no-new */
import Vue from "vue";
import * as Cookies from "js-cookie";
import config from "config";
import { decoder } from "@/utils/sessions/jwt";
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
import nav from "../_nav";
import DashboardNavbar from "./DashboardNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import { FadeTransition } from "vue2-transitions";
import { BASE_URL, STATIC_URL } from "@/api/constants/baseUrl";
import { loadStripeCheckout } from "@/utils/loadStripe";

Vue.use(Cookies);

function hasElement(className) {
  return document.getElementsByClassName(className).length > 0;
}

function initScrollbar(className) {
  if (hasElement(className)) {
    new PerfectScrollbar(`.${className}`);
  } else {
    // try to init it later in case this component is loaded async
    setTimeout(() => {
      initScrollbar(className);
    }, 100);
  }
}

export default {
  name: "DashboardLayout",
  components: {
    DashboardNavbar,
    ContentFooter,
    // DashboardContent,
    FadeTransition,
  },
  data() {
    return {
      navList: [],
      staticUrl: STATIC_URL,
      userGroup: [],
      openBillingModal: false,
      billing: {
        card: {
          fullName: undefined,
          cardNumber: undefined,
          expiration: undefined,
          cv: undefined,
          postal_code: undefined,
          city: undefined,
          state: undefined,
        },
        address: {
          line1: undefined,
          line2: undefined,
          city: undefined,
          state: null,
          postal_code: undefined,
        },
        temp: {
          subscriptionLoading: false,
          hasCardError: false,
          cardError: "",
          cardInput: undefined,
          card: undefined,
          stateOptions: [
            { value: null, text: "SELECT FROM BELOW", disabled: true },
            { value: "AL", text: "Alabama" },
            { value: "AK", text: "Alaska" },
            { value: "AZ", text: "Arizona" },
            { value: "AR", text: "Arkansas" },
            { value: "CA", text: "California" },
            { value: "CO", text: "Colorado" },
            { value: "CT", text: "Connecticut" },
            { value: "DE", text: "Delaware" },
            { value: "DC", text: "District Of Columbia" },
            { value: "FL", text: "Florida" },
            { value: "GA", text: "Georgia" },
            { value: "HI", text: "Hawaii" },
            { value: "ID", text: "Idaho" },
            { value: "IL", text: "Illinois" },
            { value: "IN", text: "Indiana" },
            { value: "IA", text: "Iowa" },
            { value: "KS", text: "Kansas" },
            { value: "KY", text: "Kentucky" },
            { value: "LA", text: "Louisiana" },
            { value: "ME", text: "Maine" },
            { value: "MD", text: "Maryland" },
            { value: "MA", text: "Massachusetts" },
            { value: "MI", text: "Michigan" },
            { value: "MN", text: "Minnesota" },
            { value: "MS", text: "Mississippi" },
            { value: "MO", text: "Missouri" },
            { value: "MT", text: "Montana" },
            { value: "NE", text: "Nebraska" },
            { value: "NV", text: "Nevada" },
            { value: "NH", text: "New Hampshire" },
            { value: "NJ", text: "New Jersey" },
            { value: "NM", text: "New Mexico" },
            { value: "NY", text: "New York" },
            { value: "NC", text: "North Carolina" },
            { value: "ND", text: "North Dakota" },
            { value: "OH", text: "Ohio" },
            { value: "OK", text: "Oklahoma" },
            { value: "OR", text: "Oregon" },
            { value: "PA", text: "Pennsylvania" },
            { value: "RI", text: "Rhode Island" },
            { value: "SC", text: "South Carolina" },
            { value: "SD", text: "South Dakota" },
            { value: "TN", text: "Tennessee" },
            { value: "TX", text: "Texas" },
            { value: "UT", text: "Utah" },
            { value: "VT", text: "Vermont" },
            { value: "VA", text: "Virginia" },
            { value: "WA", text: "Washington" },
            { value: "WV", text: "West Virginia" },
            { value: "WI", text: "Wisconsin" },
            { value: "WY", text: "Wyoming" },
          ],
        },
      },
    };
  },
  computed: {
    profileLoading() {
      return this.$store.state.user.loading || "";
    },
    navItems() {
      return nav.items.filter((nav) => {
        return nav.userGroup.includes(this.userGroup);
      });
    },
  },
  methods: {
    initScrollbar() {
      initScrollbar("scrollbar-inner");
    },
    loadProfile(userGroup) {
      if (!this.$store.state.user.profileLoaded) {
        this.$store.dispatch("user/USER_PROFILE_REQUEST").then((resp) => {});
      }
    },
    checkBillingStatus(token) {
      if (!this.$store.state.billingUpdateRequired) {
        this.$store
          .dispatch("user/CHECK_BILLING_COMPLETE", token)
          .then((complete) => {
            if (!complete) this.openCardFormModal();
          });
      }
    },
    showIdle() {
      let timerInterval;
      let username = this.$store.state.user.firstname;
      this.$swal
        .fire({
          position: "center",
          imageUrl: this.staticUrl + "/img/idle.png",
          imageWidth: 150,
          title: "Your session has expired",
          html: "Hi <strong></strong> your being timed out due to inactivity. If you want to continue your session kindly click continue. <b></b>",
          showConfirmButton: true,
          showCancelButton: false,
          confirmButtonText: "Continue",
          confirmButtonClass: "btn btn-primary pr-5 pl-5",
          footer:
            '<div class="text-center"><p class="font-sm text-gray mb-0">Your session will automatically ends in <strong id="countoff"></strong> secs.</p></div>',
          buttonsStyling: false,
          allowOutsideClick: false,
          timer: 20000,
          onBeforeOpen: () => {
            this.$swal.getContent().querySelector("strong").textContent =
              username;

            /*timerInterval = setInterval(() => {
              let timeleft
              timeleft = Math.round(this.$swal.getTimerLeft() / 1000)
              // document.getElementById('countof').value = timeleft
              // this.$swal.getContent().querySelector('#countoff').innerHTML = timeleft
              // this.$swal.getFooter().querySelector('countoff').innerHTML = timeleft
            }, 100)*/

            timerInterval = setInterval(() => {
              this.$swal.getFooter().querySelector("#countoff").innerHTML = (
                this.$swal.getTimerLeft() / 1000
              ).toFixed(0);
            }, 100);
          },
          onClose: () => {
            clearInterval(timerInterval);
          },
        })
        .then((result) => {
          if (result) {
            const { dismiss, value } = result;
            if (!value && dismiss === "timer") {
              this.logout();
            }
          }
          /*if (result.dismiss === this.$swal.DismissReason.timer) {
            // console.log('I was closed by the timer')
            this.logout()
          }*/
        });
    },
    logout: function () {
      this.$store
        .dispatch("auth/AUTH_LOGOUT")
        .then(() => this.$router.push("/login"));
    },
    closeWarning() {
      Cookies.set("browser-warning", "0", { expires: 3 });
      this.$bvModal.hide("browser_warning");
    },
    openCardFormModal() {
      this.openBillingModal = false;

      if (this.openBillingModal == true) {
        const stripePk =
          process.env.NODE_ENV == "development" || process.env.NODE_ENV == "staging"
            ? `${process.env.VUE_APP_STRIPE_PK_TEST_KEY}`
            : `${process.env.VUE_APP_STRIPE_PK_LIVE_KEY}`;
        // const stripePk = process.env.VUE_APP_STRIPE_PK_LIVE_KEY
        const style = {
          base: {
            color: "#8898aa",
            fontFamily: '"Avenir Book", sans-serif',
            fontSize: "15px",
          },
        };
        const initializeStripe = () => {
          this.billing.temp.stripe = Stripe(stripePk);
          this.billing.temp.card = this.billing.temp.stripe
            .elements()
            .create("card", { style });
          setTimeout(() => this.billing.temp.card.mount("#card-element"), 500);
          this.billing.temp.card.on("change", (card) => {
            this.billing.temp.hasCardError = false;
            this.billing.temp.cardInput = card;
          });
        };
        !window.Stripe
          ? loadStripeCheckout(stripePk, "v3", initializeStripe)
          : initializeStripe();
      }
    },
    addCard() {
      const promiseBtn = document.getElementById("cardFormAction");
      const btnLoader = promiseBtn.querySelector(".btn-loader");
      btnLoader.classList.remove("hidden");
      promiseBtn.setAttribute("disabled", "disabled");

      if (this.cardInput == undefined) {
        this.hasCardError = true;
        this.cardError = "Please input card number";
        btnLoader.classList.add("hidden");
        promiseBtn.removeAttribute("disabled", "disabled");
      }
      return this.$validator.validateAll().then(async (isValid) => {
        if (isValid) {
          const { error, token } = await this.stripe.createToken(this.card, {
            name: this.billing.card.fullName,
            address_line1: this.billing.address.line1,
            address_city: this.billing.address.city,
            address_state: this.billing.address.state,
            address_postal_code: this.billing.address.postal_code,
          });

          if (error) {
            this.$notify({
              message: this.$options.filters.capitalizeWords(error.message),
              timeout: 4000,
              icon: "fas fa-exclamation-triangle",
              type: "danger",
            });
            btnLoader.classList.add("hidden");
            promiseBtn.removeAttribute("disabled", "disabled");
          }

          const cardData = {
            token: token.id,
            name: this.billing.card.fullName,
            address: {
              line1: this.billing.address.line1,
              city: this.billing.address.city,
              state: this.billing.address.state,
              postal_code: this.billing.address.postal_code,
            },
          };
          this.$store
            .dispatch("subscriptions/ADD_CARD", {
              userId: this.currentUser._id,
              cardData,
            })
            .then(async (res) => {
              if (res.data.success) {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    "Card Successfully Added!"
                  ),
                  timeout: 5000,
                  icon: "fas fa-check",
                  type: "success",
                });

                this.openCardForm = false;
                this.hasCard = true;
                location.reload();
              } else {
                console.log(res.data.error);
                this.$notify({
                  message:
                    this.$options.filters.capitalizeWords("Error adding card."),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
              }
              btnLoader.classList.add("hidden");
              promiseBtn.removeAttribute("disabled", "disabled");
            })
            .catch((err) => {
              this.openCardForm = false;
              err.then((error) => {
                this.$notify({
                  message: this.$options.filters.capitalizeWords(
                    error.errMessage
                  ),
                  timeout: 4000,
                  icon: "fas fa-exclamation-triangle",
                  type: "danger",
                });
                btnLoader.classList.add("hidden");
                promiseBtn.removeAttribute("disabled", "disabled");
              });
            });
        } else {
          btnLoader.classList.add("hidden");
          promiseBtn.removeAttribute("disabled", "disabled");
        }
      });
    },
    closeModal() {
      this.openBillingModal = false;
      Cookies.set("ignore-add-billing", true);
      // alert(
      //   "Your billing info is required before your business will be displayed in the app"
      // );
    },
  },
  onIdle() {
    this.showIdle();
    document.documentElement.style.overflow = "auto";
  },
  mounted() {
    const token = Cookies.get("user-token");
    const browserWarning = Cookies.get("browser-warning");

    const decode = decoder({
      secret: config.secret,
    });

    var payload = decode(token);

    this.userGroup = payload.userGroup;
    this.loadProfile(payload.userGroup);

    if (!Cookies.get("ignore-add-billing")) {
      this.checkBillingStatus(token);
    }

    if (this.navItems.length > 0) {
      this.initScrollbar();
    }

    const browserInfo = this.$options.filters.getBrowserInfo();

    if (browserInfo[0] !== "Chrome" && browserInfo[1] <= 79) {
      if (browserWarning !== "0") {
        this.$bvModal.show("browser_warning");
      }
    }
  },
};
</script>
<style lang="scss" scoped>
#companyID {
  color: #172b4d !important;
}
.navbar-brand {
  text-transform: uppercase;
  color: #fff !important;
  font-weight: bold;
  font-size: 18px;
}
.title-with-image {
  position: absolute;
  top: 20px;
  margin-left: 10px;
}
.sidenav .navbar-brand {
  padding: 0.5rem 1rem !important;
}
.navbar-vertical
  .navbar-nav
  .nav-link[data-toggle="collapse"][aria-expanded="true"]:after {
  color: #25bfa4 !important;
}
.btn-loader {
  font-size: 20px;
  &.hidden {
    display: none;
  }
}
.form-card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.05);
  border-radius: 0.375rem;
  margin-bottom: 10px;
  -webkit-box-shadow: 0 0 1rem 0 #00000020;
  box-shadow: 0 0 1rem 0 #00000020;
  border: 0;
  height: 38px;
  padding: 10px;
}
.form-group {
  margin-bottom: 5px;
}
.modal-description {
  margin-bottom: 10px;
  font-size: 0.95rem;
}
/deep/ .custom-modal > .modal-dialog > .modal-content {
  .modal-body {
    padding: 10px 20px 0;
  }
}
</style>
