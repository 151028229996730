<template>
  <router-view></router-view>
</template>

<script>
// Polyfills for js features used in the Dashboard but not supported in some browsers (mainly IE)
import "@/polyfills";
// element ui language configuration
import lang from "element-ui/lib/locale/lang/en";
import locale from "element-ui/lib/locale";
locale.use(lang);

// asset imports
import "@/assets/sass/argon.scss";

export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {
    if (
      process.env.NODE_ENV !== "development" &&
      location.protocol !== "https:"
    ) {
      location.replace(
        `https:${location.href.substring(location.protocol.length)}`
      );
    }

    if (process.env.NODE_ENV === "development") {
      document.title = "XB Admin Local";
    } else if (process.env.NODE_ENV === "staging") {
      document.title = "XB Admin Dev";
    }

    if (this.$store.state.auth.token) {
      if (!this.$store.state.user.profileLoaded) {
        // this.$store.dispatch('user/USER_PROFILE_REQUEST')
      }
    }
  },
};
</script>
<style lang="scss">
/* Import Font Awesome Icons Set */
$fa-font-path: "~font-awesome/fonts/";
@import "~font-awesome/css/font-awesome.min.css";
/* Import Bootstrap Vue Styles */
@import "~bootstrap-vue/dist/bootstrap-vue.css";
/* Import Date Picker */
@import "~vue-ctk-date-time-picker/dist/vue-ctk-date-time-picker.css";
/* Import Vue Select */
@import "~vue-multiselect/dist/vue-multiselect.min.css";

.swal2-container .btn-secondary {
  border: 1px solid #eaeaea !important;
}

.g-sidenav-pinned.g-sidenav-show.modal-open {
  padding: 0 !important;
  overflow-y: auto !important;
}

// .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable, .ck.ck-editor__main>.ck-editor__editable.ck-rounded-corners {
//   background: black !important;
// }

// .ck.ck-editor__main>.ck-editor__editable {
//   background: black !important;
// }

// .cke_wysiwyg_frame, .cke_wysiwyg_div { background-color: red; }
</style>
