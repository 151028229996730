const mutations = {
  GET_BUSINESS_LIST_REQUEST: (state) => {
    state.loading = true
    state.loadedOnce = false
    state.userloadedOnce = false
    state.success = false
    state.error = false
  },
  SET_BUSINESS_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.loadedOnce = true
    state.userloadedOnce = true
    state.success = true
    state.error = false
    state.errorUser = false
    state.businessList = data
    state.selectedUser = {}
  },
  SET_CLIENT_USER_SUCCESS: (state, data) => {
    state.loading = false
    state.loadedOnce = true
    state.userloadedOnce = true
    state.success = true
    state.error = false
    state.errorUser = false
    state.userClientList = data
    state.selectedUser = {}
  },
  GET_BUSINESS_LIST_FAILED: (state) => {
    state.loading = false
    state.userloadedOnce = false
    state.businessList = {}
  },
  GET_BUSINESS_USER_BY_ID_REQUEST: (state) => {
    state.loading = true
    state.selectedUserSuccess = false
    state.selectedUserError = false
  },
  SET_BUSINESS_USER_BY_ID: (state, data) => {
    state.loading = false
    state.selectedUserSuccess = true
    state.selectedUser = data
  },
  GET_BUSINESS_USER_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedUserError = true
    state.selectedUser = {}
  },
  UPDATE_BUSINESS_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_BUSINESS_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_BUSINESS_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  RESET_DATA: (state) =>{
    state.loading = false
    state.loadedOnce = false
    state.userloadedOnce = false
    state.success = false
    state.error = false
    state.errorUser = false
    state.businessList = {}
    state.selectedUser = {}
    state.selectedUserSuccess = false
    state.selectedUserError = false
  },
  GET_CLIENT_BY_ID_REQUEST: (state) => {
    state.loading = true
    state.selectedUserSuccess = false
    state.selectedUserError = false
  },
  SET_CLIENT_USER_BY_ID: (state, data) => {
    state.loading = false
    state.selectedUserSuccess = true
    state.selectedUser = data
  },
  GET_CLIENT_USER_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedUserError = true
    state.selectedUser = {}
  },
}

export default mutations
