const mutations = {
  GET_USERS_REQUEST: (state) => {
    state.loading = true
    state.loadedOnce = false
    state.usersLoadedOnce = false
    state.success = false
    state.error = false
    state.selectedUser = {}
  },
  UPDATE_USERS_INFO_REQUEST: (state) => {
    state.updateLoading = true
    state.success = false
    state.error = false
  },
  SET_USERS_SUCCESS: (state, data) => {
    state.loading = false
    state.loadedOnce = true
    state.usersLoadedOnce = true
    state.success = true
    state.error = false
    state.errorUser = false
    state.userList = data
    state.selectedUser = {}
  },
  SET_UPDATE_USERS_SUCCESS: (state, data) => {
    state.updateLoading = false
    state.success = true
    state.error = false
  },
  SET_DELETED_USER_SUCCESS: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
  },
  GET_USERS_FAILED: (state) => {
    state.loading = false
    state.usersLoadedOnce = false
    state.userList = []
  },
  SET_UPDATE_USERS_FAILED: (state, data) => {
    state.updateLoading = false
    state.success = false
    state.error = true
  },
  GET_USERS_BY_CP_ID_REQUEST: (state) => {
    state.loading = true
    state.selectedUserSuccess = false
    state.selectedUserError = false
  },
  SET_USERS_BY_ID: (state, data) => {
    state.loading = false
    state.selectedUserSuccess = true
    state.selectedUser = data
  },
  GET_USERS_BY_CP_ID_FAILED: (state) => {
    state.loading = false
    state.selectedUserError = true
    state.selectedUser = {}
  },
  UPDATE_USERS_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_USERS_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_USERS_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  GET_USERS_NOTES_REQUEST: (state) => {
    state.notesLoading = true
    state.notesSuccess = false
    state.notesError = false
    state.notes = []
  },
  SET_USERS_NOTES_SUCCESS: (state, data) => {
    state.notesLoading = false
    state.notesSuccess =  true
    state.notesError = false
    state.notes = data
  },
  SET_USERS_NOTES_FAILED: (state) => {
    state.notesLoading = false
    state.notesSuccess = false
    state.notesError = true
    state.notes = []
  },
  RESET_DATA: (state) =>{
    state.loading = false
    state.updateLoading = false
    state.loadedOnce = false
    state.usersLoadedOnce = false
    state.groupLoading = false
    state.success = false
    state.error = false
    state.groupSuccess = false
    state.groupError = false
    state.errorUser = false
    state.errorGroup = false
    state.userList = []
    state.groupList = []
    state.selectedUser = {}
    state.selectedGroup = {}
    state.selectedUserSuccess = false
    state.selectedUserError = false
    state.selectedGroupSuccess = false
    state.selectedGroupError = false,
    state.userRecoveredList = []
  }
}

export default mutations
