/*
 API request UTILITY
 with params : (url, data, method, header)
*/
import axios from 'axios'
// import ApiMock from '@/utils/api'
import { API_URL } from '../constants/baseUrl'

const $http = (url, data, method, header = null, customAPI) => new Promise((resolve, reject) => {
  let trimUrl = url.substr(1)
  let apiUrl = `${API_URL}/${trimUrl}`

  if (customAPI !== undefined && customAPI) {
    apiUrl = url
  }

  const options = {url: apiUrl, data: data, method: method}

  if (header !== null) {
    options.headers = header
  }

  axios(options)
    .then(resp => {
      resolve(resp)
    })
    .catch(err => {
      reject(err)
    })
})

export default $http
