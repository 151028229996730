import $http from '../utils/http'
import { headersJsonOnly } from '../constants/headers'
import Vue from 'vue'
import * as Cookies from 'js-cookie'

Vue.use(Cookies)

/*
  $http (url, data, method, header)
*/

const forgotPassword = email => {
  return $http(`/user/forgot-password`, email, 'POST', headersJsonOnly)
}

const confirmPasswordToken = (token) => {
  const header = (token) ? { 'authorization': token, 'x-company-header': Cookies.get('companyID') } : null
  return $http(`/user/forgot-password/${token}`, null, 'GET', header)
}

const changePassword = (token, data) => {
  const header = (token) ? { 'authorization': token, 'x-company-header': Cookies.get('companyID') } : null
  return $http(`/user/change-password`, data, 'PUT', header)
}

const resetPassword = (token, userID) => {
  const header = (token) ? { 'authorization': token, 'x-company-header': Cookies.get('companyID') } : null
  return $http(`/user/reset-password/id/${userID}`, '', 'GET', header)
}

export {
  forgotPassword,
  changePassword,
  confirmPasswordToken,
  resetPassword
}
