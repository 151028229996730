const mutations = {
  loading (state) {
    state.loading = true
  },
  CREATE_CONTENT_REQUEST: state => {
    state.loading = true
  },
  CREATE_CONTENT_SUCCESS: state => {
    state.loading = false
  },
  CREATE_CONTENT_FAILED: state => {
    state.loading = false
  },
  GET_CONTENT_LIST_REQUEST: (state) => {
    state.loading = true
  },
  SET_CONTENT_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.contentsList = data
  },
  GET_CONTENT_BY_ID_REQUEST: (state) => {
    state.loading = true
  },
  GET_CONTENT_LIST_FAILED: (state) => {
    state.loading =false
  },
  SET_CONTENT_BY_ID: (state, data) => {
    state.loading = false
    state.selectedCulture = data
  },
  GET_CONTENT_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedCulture = {}
  },
  UPDATE_CONTENT_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_CONTENT_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_CONTENT_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  setCultureCategoryList (state, payload) {
    state.loading = false
    state.loadedOnce = true
    state.cultureCategories = payload.data
  },
  setCityList (state, payload) {
    state.loading = false
    state.loadedOnce = true
    state.cities = payload.data
  },
  setSubCategoryList (state, payload) {
    state.loading = false
    state.loadedOnce = true
    state.subCategories = payload.data
  },
}

export default mutations
