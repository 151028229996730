import Vue from 'vue'
import Cookies from 'js-cookie'
import {
  getVideos,
  createVideo,
  updateVideo,
  deleteVideo
} from '@/api/request/videos'

Vue.use(Cookies)

const actions = {
  GET_VIDEOS: async ({ commit, dispatch }, query) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { success, data, message } } = await getVideos(token, query)
      commit('loading', false)
      commit('videos', data)
      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  UPDATE_VIDEO: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)
    
    try {
      let { data: { success, data, message } } = await updateVideo(token, formData)
      commit('loading', false)
      dispatch('GET_VIDEOS')
      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  CREATE_VIDEO: async ({ commit, dispatch }, formData) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)

    try {
      let { data: { data } } = await createVideo(token, formData)
      dispatch('GET_VIDEOS')
      return data
    } catch (error) {
      const { success, message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
  DELETE_VIDEO: async ({ commit, dispatch }, id) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('loading', true)
    
    try {
      let { data: { success, data, message } } = await deleteVideo(token, id)
      commit('loading', false)

      dispatch('GET_VIDEOS')

      return data
    } catch (error) {
      const { message } = error.response.data
      commit('loading', false)
      throw new Error(message)
    }
  },
}

export default actions
