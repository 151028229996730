import $http from '../utils/http'

/*
  $http (url, data, method, header)
*/

const getCoupon = (token, promoCode) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/advertisementPlans/coupon/promocode/${promoCode}`, null, 'GET', header)
}

const getAdvertisementPlans = (token, query) => {
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : ''
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/advertisementPlans${query}`, null, 'GET', header)
}

const getSubscriptions = (token, query) => {
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : ''
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/plans${query}`, null, 'GET', header)
}

const getPlans = (token, query) => {
  query = query && typeof query === 'object' ? `?${new URLSearchParams(query).toString()}` : ''
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/plans${query}`, null, 'GET', header)
}

const getUserSubscriptionByID = (token, userId) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/userSubscription/${userId}`, null, 'GET', header)
}

const getSubscriptionByID = (token, subscriptionID) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/plans/${subscriptionID}`, null, 'GET', header)
}

const getCard = (token, userId) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/${userId}/card`, null, 'GET', header)
}

const addCard = (token, userId, cardData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/${userId}/card`, cardData, 'PUT', header)
}

const updateCard = (token, userId, cardData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/${userId}/card`, cardData, 'PUT', header)
}

const updateSubscription = (token, subscriptionID, subscriptionData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/plans/${subscriptionID}`, subscriptionData, 'PUT', header)
}

const renewSubscription = (token, userId) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/${userId}/renew`, null, 'PUT', header)
}

const cancelSubscription = (token, userId) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/subscriptions/${userId}/cancel`, null, 'PUT', header)
}

export {
  getSubscriptions,
  getPlans,
  getUserSubscriptionByID,
  getSubscriptionByID,
  getAdvertisementPlans,
  getCard,
  addCard,
  updateCard,
  updateSubscription,
  renewSubscription,
  cancelSubscription,
  getCoupon
}
