const getters = {
  status: state => state.profileStatus,
  user: state => state.user,
  fullName: state => state.user.fullName,
  email: state => state.user.email,
  userID: state => state.user.userID,
  loading: state => state.loading,
  billingUpdateRequired: state => state.billingUpdateRequired,
  profileLoaded: state => state.profileLoaded,
  changePasswordLoading: state => state.changePasswordLoading,
  changePasswordStatus: state => state.changePasswordStatus,
  updateProfileLoading: state => state.updateProfileLoading
}

export default getters
