import Vue from 'vue'
const mutations = {
  FORGOT_PASSWORD_REQUEST: state => {
    state.status = 'loading'
  },
  FORGOT_PASSWORD_SUCCESS: (state, resp) => {
    state.forgotPasswordStatus = 'success'
    state.forgotPasswordToken = resp.data
    state.hasLoadedOnce = true
  },
  FORGOT_PASSWORD_ERROR: state => {
    state.forgotPasswordStatus = 'error'
    state.hasLoadedOnce = true
  },
  USER_PROFILE_REQUEST: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  USER_PROFILE_SUCCESS: (state, data) => {
    state.status = 'success'
    state.loading = false
    state.profileLoaded = true
    state.firstname = typeof data.firstname !== 'undefined' ? data.firstname : ''
    state.lastname = typeof data.firstname !== 'undefined' ? data.lastname : ''
    state.email = data.email
    state.userID = data._id
    state.image = data.image || ''
    state.phone = data.phone || ''
    state.userGroup = data.userGroup
    Vue.set(state, 'user', data)
  },
  USER_PROFILE_ERROR: (state) => {
    state.status = 'error'
    state.loading = false
  },
  CHECK_BILLING_COMPLETE: (state) => {
    state.status = 'loading'
    state.loading = true
  },
  BILLING_UPDATE_REQUIRED: (state, updateRequired) => {
    state.status = 'success'
    state.loading = false
    state.billingUpdateRequired = updateRequired
  },
  RESET_PROFILE_STATE_DATA: (state) => {
    state.status = ''
    state.user = {}
    state.fullName = ''
    state.firstname = ''
    state.lastname = ''
    state.email = ''
    state.phone = ''
    state.userID = ''
    state.companyID = ''
    state.userGroup = ''
  },
  UPDATE_USER_PROFILE: (state) => {
    state.updateProfileLoading = true
  },
  UPDATE_USER_PROFILE_SUCCESS: (state, updatedUser) => {
    state.user = updatedUser
    state.updateProfileLoading = false
  },
  UPDATE_USER_PROFILE_ERROR: (state, update) => {
    state.updateProfileLoading = false
  },
  CHANGE_PASSWORD_REQUEST: state => {
    state.passwordChangesStatus = 'loading'
  },
  CHANGE_PASSWORD_SUCCESS: (state, resp) => {
    state.passwordChangesStatus = 'success'
    state.passwordChangesStatusToken = resp.data
  },
  CHANGE_PASSWORD_ERROR: state => {
    state.passwordChangesStatus = 'error'
  },
  CONFIRM_PASSWORD_TOKEN_REQUEST: state => {
    state.confirmPasswordTokenStatus = 'loading'
  },
  CONFIRM_PASSWORD_TOKEN_SUCCESS: (state, resp) => {
    state.confirmPasswordTokenStatus = 'success'
  },
  CONFIRM_PASSWORD_TOKEN_ERROR: state => {
    state.confirmPasswordTokenStatus = 'error'
  },
  REGISTER_USER_REQUEST: state => {
    state.registerLoading = true
  },
  REGISTER_USER_SUCCESS: state => {
    state.registerLoading = false
  },
  REGISTER_USER_FAILED: state => {
    state.registerLoading = false
  },
  RESET_PASSWORD_REQUEST: state => {
    state.status = 'loading'
  },
  RESET_PASSWORD_SUCCESS: (state, resp) => {
    state.resetPasswordStatus = 'success'
    state.resetPasswordToken = resp.data
    state.hasLoadedOnce = true
  },
  RESET_PASSWORD_ERROR: state => {
    state.resetPasswordStatus = 'error'
    state.hasLoadedOnce = true
  },
  GET_USER_DASHBOARD: (state) => {
    state.dashboardStatus = 'loading'
    state.loading = true
    state.dashboardSuccess = true
    state.dashboardErrors = false
  },
  GET_USER_DASHBOARD_SUCCESS: (state, resp) => {
    state.dashboardStatus = 'success'
    state.loading = false
    state.hasLoadedOnce = true
    state.dashboardSuccess = true
    state.dashboardErrors = false
    state.dashboard = resp
  },
  GET_USER_DASHBOARD_ERROR: (state) => {
    state.dashboardStatus = 'error'
    state.loading = false
    state.hasLoadedOnce = true
    state.dashboardSuccess = false
    state.dashboardErrors = true
    state.dashboard = []
  },
  USER_UPDATE_CP: (state, cp) => {
    state.contentProviderID = cp
  },
  USER_RESET_STATE_DATA: (state) => {
    state.status = ''
    state.profileLoaded = false
    state.user = {}
    state.fullName = ''
    state.firstname = ''
    state.lastname = ''
    state.email = ''
    state.phone = ''
    state.userID = ''
    state.companyID = ''
    state.company = {}
    state.userGroup = ''
    state.contentProviderID = ''
    state.cpName = ''
    state.image = ''
    state.billingInfo = {}
    state.loading = false
    state.updateProfileLoading = false
    state.registerLoading = false
    state.resetPasswordStatus = false
    state.resetPasswordToken = false
    state.hasLoadedOnce = false
    state.dashboardErrors = false
    state.dashboardSuccess = false
    state.dashboardStatus = false
    state.billingUpdateRequired = false
    state.dashboard = {
      androidSubscribers: false,
      companies: false,
      coopFunds: false,
      iosSubscribers: false,
      paidSubscribers: false,
      subscribers: false,
      recoveredUsers: false,
      dashboardUsers: [],
      dashboardUsersYear: []
    }
  }
}

export default mutations
