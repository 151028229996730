const mutations = {
  GET_ADVERTISEMENTPLANS_LIST_REQUEST (state) {
    state.loading = true
    state.success = false
    state.error = false
  },
  GET_ADVERTISEMENTPLANS_LIST_SUCCESS (state, data) {
    state.loading = false
    state.success = true
    state.error = false
    state.advertisementList = data
  },
  GET_ADVERTISEMENTPLANS_LIST_FAILED (state) {
    state.loading = false
    state.success = false
    state.error = true
    state.advertisementList = {}
  },
  GET_SUBSCRIPTIONS_LIST_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  SET_SUBSCRIPTIONS_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.subscriptionsList = data
  },
  SET_PLANS_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.plans = data
  },
  GET_SUBSCRIPTIONS_FAILED: (state) => {
    state.loading = false
    state.subscriptionsList = {}
  },
  GET_SUBSCRIPTION_BY_ID_REQUEST: (state) => {
    state.loading = true
  },
  GET_USER_SUBSCRIPTION_BY_ID_REQUEST: (state) => {
    state.loading = true
  },
  SET_USER_SUBSCRIPTION_BY_ID: (state, data) => {
    state.loading = false
    state.userSubscription = data
  },
  GET_USER_SUBSCRIPTION_BY_ID_FAILED: (state) => {
    state.loading = false
    state.userSubscription = {}
  },
  SET_SUBSCRIPTION_BY_ID: (state, data) => {
    state.loading = false
    state.selectedSubscription = data
  },
  GET_SUBSCRIPTION_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedSubscription = {}
  },
  GET_CARD_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  GET_CARD_SUCCESS: (state, data) =>{
    state.loading = false
    state.success = true
    state.error = false
    state.card = data
  },
  GET_CARD_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  UPDATE_CARD_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_CARD_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_CARD_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  UPDATE_SUBSCRIPTION_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  UPDATE_SUBSCRIPTION_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  UPDATE_SUBSCRIPTION_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  RENEW_SUBSCRIPTION_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  RENEW_SUBSCRIPTION_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  RENEW_SUBSCRIPTION_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  CANCEL_SUBSCRIPTION_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  CANCEL_SUBSCRIPTION_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  CANCEL_SUBSCRIPTION_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
}
export default mutations
