import Vue from 'vue'
import {
  getSettings,
  updateSettings,
} from '@/api/request/appconfigs'
import Cookies from 'js-cookie'
import errors from '@/utils/errors'
import { globalStore } from '@/utils/global'

Vue.use(Cookies)

const actions = {
  GET_SETTINGS: ({commit, dispatch}, companyID=null) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('GET_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {

      return getSettings(token, companyID)
        .then(resp => {
          if (resp) {
            if (resp.data.data) {
              let content = resp.data.data
              commit('SET_CONTENT_SUCCESS', content)
              resolve(content)
            } else {
              commit('GET_CONTENT_FAILED')
            }
          }
        })
        .catch(err => {
          commit('GET_CONTENT_FAILED')
          reject(errors(err))
        })
    })
  },
  UPDATE_SETTINGS: ({commit, dispatch}, content) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONTENT_REQUEST')
    return new Promise((resolve, reject) => {
      return updateSettings(token, content)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONTENT_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONTENT_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONTENT_FAILED')
          reject(errors(err))
        })
    })

  },
}
export default actions
