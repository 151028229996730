import $http from '../utils/http'

/*
  $http (url, data, method, header)
*/



const getAdsList = (token) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads`, null, 'GET', header)
}

const getAdsByID = (token, adsID) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads/${adsID}`, null, 'GET', header)
}

const getBusinessAdsID = (token, businessID) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads?business=${businessID}`, null, 'GET', header)
}

const approveAds = (token, adsID, adsData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads/${adsID}`, adsData, 'PUT', header)
}

const setAsFeatured = (token, adsID, adsData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads/${adsID}/set-featured`, adsData, 'PUT', header)
}

const rejectAds = (token, adsID, adsData) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/ads/${adsID}`, adsData, 'PUT', header)
}

export {
  getAdsList,
  getAdsByID,
  getBusinessAdsID,
  setAsFeatured,
  approveAds,
  rejectAds
}
