import Vue from 'vue'
import {
  getSettings,
  createSettings
} from '@/api/request/settings'
import { uploadFile } from '@/api/request/tools'
import Cookies from 'js-cookie'
import errors from '@/utils/errors'
import { globalStore } from '@/utils/global'
import moment from 'moment'

Vue.use(Cookies)

const actions = {
  GET_SETTINGS: ({commit, dispatch}) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('GET_SETTINGS_REQUEST')
    return new Promise((resolve, reject) => {
      return getSettings(token)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              let settings = resp.data.data

              commit('GET_SETTINGS_SUCCESS', settings)
              resolve(settings)
            }
          }
        })
        .catch(err => {
          commit('GET_SETTINGS_FAILED')
          reject(errors(err))
        })
    })
  },
  GET_SETTINGS_BY_CP_ID: ({commit, dispatch}, {settingName, contentProviderID, companyID}) => {
    const token = Cookies.get('user-token') || globalStore.token || token

    commit('GET_SETTINGS_REQUEST')
    if (settingName === 'menu-color') {
      commit('GET_SETTINGS_BACKGROUND')
    } else if (settingName === 'social-media') {
      commit('GET_SETTINGS_SOCIAL_MEDIA')
    } else if (settingName === 'custom-menu') {
      commit('GET_SETTINGS_CUSTOM_MENU')
    }

    return new Promise((resolve, reject) => {
      return getSettingsByCPID(token, settingName, contentProviderID, companyID)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('GET_SETTINGS_SUCCESS', resp.data.data)
              if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE')
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE')
              } else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_DONE')
              }
              resolve(resp.data.data)
            }
          }
        })
        .catch(err => {
          commit('GET_SETTINGS_FAILED')
          if (settingName === 'menu-color') {
            commit('GET_SETTINGS_BACKGROUND_DONE')
          } else if (settingName === 'social-media') {
            commit('GET_SETTINGS_SOCIAL_MEDIA_DONE')
          } else if (settingName === 'custom-menu') {
            commit('GET_SETTINGS_CUSTOM_MENU_DONE')
          }
          reject(errors(err))
        })
    })
  },
  CREATE_SETTINGS: ({commit, dispatch}, {settingName, settingsData}) => {
    const token = Cookies.get('user-token') || globalStore.token || token

    commit('GET_SETTINGS_REQUEST')
    if (settingName === 'vobo-connect') {
      commit('GET_SETTINGS_VOBO_CONNECT')
    } else if (settingName === 'access-code') {
      commit('GET_SETTINGS_ACCESS_CODE')
    } else if (settingName === 'auto-phone-number') {
      commit('GET_SETTINGS_AUTO_PHONE')
    } else if (settingName === 'max-notification') {
      commit('GET_SETTINGS_MAX_NOTIF')
    } else if (settingName === 'menu-color') {
      commit('GET_SETTINGS_BACKGROUND')
    } else if (settingName === 'social-media') {
      commit('GET_SETTINGS_SOCIAL_MEDIA')
    } else if (settingName === 'custom-menu') {
      commit('GET_SETTINGS_CUSTOM_MENU_SAVE')
    }

    return new Promise((resolve, reject) => {
      return createSettings(token, settingName, settingsData)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('GET_SETTINGS_SUCCESS', resp.data)
              if (settingName === 'vobo-connect') {
                commit('GET_SETTINGS_VOBO_CONNECT_DONE')
              } else if (settingName === 'access-code') {
                commit('GET_SETTINGS_ACCESS_CODE_DONE')
              } else if (settingName === 'auto-phone-number') {
                commit('GET_SETTINGS_AUTO_PHONE_DONE')
              } else if (settingName === 'max-notification') {
                commit('GET_SETTINGS_MAX_NOTIF_DONE')
              } else if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE')
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE')
              } else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE')
              }
              resolve(resp)
            } else {
              commit('GET_SETTINGS_FAILED')
              if (settingName === 'vobo-connect') {
                commit('GET_SETTINGS_VOBO_CONNECT_DONE')
              } else if (settingName === 'access-code') {
                commit('GET_SETTINGS_ACCESS_CODE_DONE')
              } else if (settingName === 'auto-phone-number') {
                commit('GET_SETTINGS_AUTO_PHONE_DONE')
              } else if (settingName === 'max-notification') {
                commit('GET_SETTINGS_MAX_NOTIF_DONE')
              } else if (settingName === 'menu-color') {
                commit('GET_SETTINGS_BACKGROUND_DONE')
              } else if (settingName === 'social-media') {
                commit('GET_SETTINGS_SOCIAL_MEDIA_DONE')
              }else if (settingName === 'custom-menu') {
                commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE')
              }
            }
          }
        })
        .catch(err => {
          commit('GET_SETTINGS_FAILED')
          if (settingName === 'vobo-connect') {
            commit('GET_SETTINGS_VOBO_CONNECT_DONE')
          } else if (settingName === 'access-code') {
            commit('GET_SETTINGS_ACCESS_CODE_DONE')
          } else if (settingName === 'auto-phone-number') {
            commit('GET_SETTINGS_AUTO_PHONE_DONE')
          } else if (settingName === 'max-notification') {
            commit('GET_SETTINGS_MAX_NOTIF_DONE')
          } else if (settingName === 'menu-color') {
            commit('GET_SETTINGS_BACKGROUND')
          } else if (settingName === 'social-media') {
            commit('GET_SETTINGS_SOCIAL_MEDIA')
          } else if (settingName === 'custom-menu') {
            commit('GET_SETTINGS_CUSTOM_MENU_SAVE_DONE')
          }
          reject(errors(err))
        })
    })

  },
  DELETE_AUTO_PHONE: ({commit, dispatch}, autoPhoneID) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('GET_SETTINGS_AUTO_PHONE')
    return new Promise((resolve, reject) => {
      return deleteAutoPhone(token, autoPhoneID)
        .then(resp => {
          if (resp) {
            commit('GET_SETTINGS_AUTO_PHONE_DONE')
            resolve(resp)
          }
        })
        .catch(err => {
          commit('GET_SETTINGS_AUTO_PHONE_DONE')
          reject(errors(err))
        })
    })
  }
}

export default actions
