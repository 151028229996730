const state = {
  voboLoading: false,
  accessLoading: false,
  backgroundLoading: false,
  autoPhoneLoading: false,
  notifMaxLoading: false,
  socialMediaLoading: false,
  customMenuLoading: false,
  customMenuLoadingSave: false,
  success: false,
  error: false,
  settingsList: []
}

export default state
