import $http from '../utils/http'

/*
  $http (url, data, method, header)
*/

const getContent = (token) => {
  const header = (token) ? {'authorization': token} : null
  return $http(`/api/blackcard`, null, 'GET', header)
}

const updateContent = (token, content) => {
  const header = (token) ? {'Content-Type': undefined, 'authorization': token} : null
  return $http(`/api/blackcard`, content, 'PUT', header)
}

export {
  getContent,
  updateContent,
}
