import Vue from 'vue'
import {
  createConnection,
  updateConnection,
  setFeatured,
  getConnectionByID,
  getConnectionList,
  getCityListRequest,
  getConnectionCategoryList,
  getCommunityCategoryList,
  getSubCategoryList,
  deleteConnection
} from '@/api/request/connections'
import Cookies from 'js-cookie'
import errors from '@/utils/errors'
import { globalStore } from '@/utils/global'
import moment from 'moment'

Vue.use(Cookies)

const actions = {
  CREATE_CONNECTION: ({commit, dispatch}, data) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('CREATE_CONNECTION_REQUEST')
    return new Promise((resolve, reject) => {
      return createConnection(token, data)
        .then(resp => {
          if (resp) {
            commit('CREATE_CONNECTION_SUCCESS', resp)
            resolve(resp)
          }else {
            commit('CREATE_CONNECTION_FAILED')
            reject('Error in creating connection')
          }
        })
        .catch(err => {
          commit('CREATE_CONNECTION_FAILED')
          reject(errors(err))
        })
    })
  },
  UPDATE_CONNECTION: ({commit, dispatch}, {connectionID, connectionData}) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONNECTION_REQUEST')
    return new Promise((resolve, reject) => {
      return updateConnection(token, connectionID, connectionData)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONNECTION_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONNECTION_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONNECTION_FAILED')
          reject(errors(err))
        })
    })

  },
  SET_FEATURED: ({commit, dispatch}, {connectionID, connectionData}) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONNECTION_REQUEST')
    return new Promise((resolve, reject) => {
      return setFeatured(token, connectionID, connectionData)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_CONNECTION_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONNECTION_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONNECTION_FAILED')
          reject(errors(err))
        })
    })

  },
  GET_CONNECTION_BY_ID: ({commit, dispatch}, connectionID) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('GET_CONNECTION_BY_ID_REQUEST')
    return new Promise((resolve, reject) => {
      return getConnectionByID(token, connectionID)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('SET_CONNECTION_BY_ID', resp.data)
              resolve(resp.data)
            } else {
              commit('GET_CONNECTION_BY_ID_FAILED')
            }
          }
        })
        .catch(err => {
          commit('GET_CONNECTION_BY_ID_FAILED')
          reject(errors(err))
        })
    })
  },
  GET_CONNECTION_LIST: ({commit}, payload) => {
    const token = Cookies.get('user-token') || globalStore.token
    commit('GET_CONNECTION_LIST_REQUEST')
    return new Promise((resolve, reject) => {
      return getConnectionList(token, payload)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              let connectionsSource = resp.data.data
              let newConnections = {}
              let now = moment().startOf('day').valueOf()
              let connectionsList = connectionsSource.map(value => {
                let category = value.category == null ? 'Category removed.': value.category
                return {
                  title:value.title,
                  description:value.description,
                  url:value.url,
                  category: category.parent ? category.parent.title : category.title || 'Category removed',
                  sub_category: category.parent ? category.title : 'No Sub Category' ,
                  city:value.city.city,
                  city_status:value.city.show,
                  cover: value.cover || '',
                  featured:value.featured == null ? false : true,
                  date:moment(value.date ).format('YYYY/MM/DD h:mm A'),
                  start:moment(value.startDate ).format('YYYY/MM/DD'),
                  end:moment(value.endDate ).format('YYYY/MM/DD'),
                  time:moment(value.time ).format('h:mm A'),
                  status: value.status,
                  createdAt: moment(new Date(value.createdAt)).format('YYYY-MM-DD HH:mm:SS'),
                  createdYmd: moment(new Date(value.createdAt)).format('YYYY-MM-DD'),
                  createdHms: moment(new Date(value.createdAt)).format('HH:mm:SS'),
                  actions: value._id
                }
              })
              connectionsList = connectionsList.sort(function (a, b) {
                return new Date(b.createdAt) - new Date(a.createdAt)
              })

              newConnections['all'] = connectionsList.filter(connection => connection)
              newConnections['featured'] = connectionsList.filter(connection => connection.featured == true)
              newConnections['ended'] = connectionsList.filter(connection => connection.end < now)
              newConnections['ongoing'] = connectionsList.filter(connection => {
                return moment(connection.start).startOf('day').valueOf() <= now && moment(connection.end).startOf('day').valueOf() >= now
              })
              newConnections['upcoming'] = connectionsList.filter(connection => moment(connection.start).startOf('day').valueOf() > now)
              commit('SET_CONNECTION_LIST_SUCCESS', newConnections)
              resolve(newConnections)
            } else {
              commit('GET_CONNECTION_LIST_FAILED')
            }
          }
        })
        .catch(err => {
          commit('GET_CONNECTION_LIST_FAILED')
          reject(errors(err))
        })
    })
  },
  GET_CITY_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getCityListRequest(token, payload)
        .then(async result => {
          if (result) {
            commit('setCityList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_CONNECTION_CATEGORY_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getConnectionCategoryList(token, payload)
        .then(async result => {
          if (result) {
            commit('setConnectionCategoryList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_COMMUNITY_CATEGORY_LIST ({commit}, payload) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getCommunityCategoryList(token, payload)
        .then(async result => {
          if (result) {
            commit('setCommunityCategoryList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  GET_SUB_CATEGORY_LIST ({commit}, parentCategoryID) {
    const token = Cookies.get('user-token') || globalStore.token
    return new Promise((resolve, reject) => {
      commit('loading')
      return getSubCategoryList(token, parentCategoryID)
        .then(async result => {
          if (result) {
            commit('setSubCategoryList', result.data)
            resolve(result)
          }
        })
        .catch(err => {
          commit('error', err)
          reject(errors(err))
        })
    })
  },
  DELETE_CONNECTION: ({commit, dispatch}, connectionID) => {
    const token = Cookies.get('user-token') || globalStore.token || token
    commit('UPDATE_CONNECTION_REQUEST')
    return new Promise((resolve, reject) => {
      return deleteConnection(token, connectionID)
        .then(resp => {
          if (resp) {
            if (resp.data) {
              commit('UPDATE_ADMIN_SUCCESS', resp.data)
              resolve(resp)
            } else {
              commit('UPDATE_CONNECTION_FAILED')
            }
          }
        })
        .catch(err => {
          commit('UPDATE_CONNECTION_FAILED')
          reject(errors(err))
        })
    })

  }
}

export default actions
