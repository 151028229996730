const mutations = {
  GET_ADS_LIST_REQUEST: (state) => {
    state.loading = true
    state.success = false
    state.error = false
  },
  SET_ADS_LIST_SUCCESS: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.adsList = data
  },
  SET_BUSINESS_ADS_INFO: (state, data) => {
    state.loading = false
    state.success = true
    state.error = false
    state.businessAdsInfo = data
  },
  GET_ADS_LIST_FAILED: (state) => {
    state.loading = false
    state.adsList = {}
  },
  GET_ADS_BY_ID_REQUEST: (state) => {
    state.loading = true
  },
  SET_ADS_BY_ID: (state, data) => {
    state.loading = false
    state.selectedAds = data
  },
  GET_ADS_BY_ID_FAILED: (state) => {
    state.loading = false
    state.selectedAds = {}
  },
  APPROVE_ADS_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  APPROVE_ADS_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  APPROVE_ADS_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
  REJECT_ADS_REQUEST: (state) =>{
    state.loading = true
    state.success = false
    state.error = false
  },
  REJECT_ADS_SUCCESS: (state) =>{
    state.loading = false
    state.success = true
    state.error = false
  },
  REJECT_ADS_FAILED: (state) =>{
    state.loading = false
    state.success = false
    state.error = true
  },
}
export default mutations
