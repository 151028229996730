const getters = {
  isAuthenticated: state => !!state.token,
  token: state => state.token,
  tokenExpired: state => state.tokenExpired,
  isFirstTimeLogin: state => state.isFirstTimeLogin,
  authStatus: state => state.status,
  userEmail: state => state.userEmail,
  userGroup: state => state.userGroup
}

export default getters
